const isProdEnv = process.env.REACT_APP_ENV === "prod";
export default {
  isProdEnv,
  apiEndpoint: "https://gateway.dev.meu-solutions.com/assetmanagement/api/v1.0",
  ssoDomain: "https://meu.anawork.com/auth/loginqlts",
  hrDomain: "https://meu.anawork.com/portal/hr",
  hrModule: "https://meu.anawork.com/dashboard",
  qldaModule: "https://project.tlr.meu-solutions.com/",
  workflowModule: "https://meu.anawork.com/portal/workflow/workitem",
  channel: "qlts",
  siteURL: "http://asset.erp.meu-solutions.com/",
  workflowDomain: "https://meu.anawork.com/portal/workflow",
};
